const Backgrounds = [
    {
        "id": 0,
        "name": "Default",
        "location": '/Heroes/Backgrounds/Base/Base_Background.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Immortal Background",
        "location": '/Heroes/Backgrounds/Faction/factionBG_1.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Dragonriders Background",
        "location": '/Heroes/Backgrounds/Faction/factionBG_2.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Ether Background",
        "location": '/Heroes/Backgrounds/Faction/factionBG_3.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 1",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_1.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 2",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_2.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 3",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_3.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 4",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_4.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 5",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_5.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 6",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_6.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 7",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_7.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 8",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_8.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 9",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_9.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Background 10",
        "location": '/Heroes/Backgrounds/Legendary/legendary_backgrounds_10.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 1",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_1.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 2",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_2.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 3",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_3.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 4",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_4.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 5",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_5.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 6",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_6.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 7",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_7.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 8",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_8.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 9",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_9.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Color Background 10",
        "location": '/Heroes/Backgrounds/Colors/background_Colors_10.png',
        "type": "Base"
    },
]

export default Backgrounds;