const Male = [
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Male/Black/Body/MEther_Black_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Male/Green/Body/MEther_Green_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Male/Grey/Body/MEther_Grey_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Male/White/Body/MEther_White_Body_40.png'
        },
    ]
]

const Female = [
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Female/Black/Body/FEther_Black_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Female/Green/Body/FEther_Green_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Female/Grey/Body/FEther_Grey_Body_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Body 1",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_1.png'
        },
        {
            "id": 0,
            "name": "Body 2",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_2.png'
        },
        {
            "id": 0,
            "name": "Body 3",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_3.png'
        },
        {
            "id": 0,
            "name": "Body 4",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_4.png'
        },
        {
            "id": 0,
            "name": "Body 5",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_5.png'
        },
        {
            "id": 0,
            "name": "Body 6",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_6.png'
        },
        {
            "id": 0,
            "name": "Body 7",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_7.png'
        },
        {
            "id": 0,
            "name": "Body 8",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_8.png'
        },
        {
            "id": 0,
            "name": "Body 9",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_9.png'
        },
        {
            "id": 0,
            "name": "Body 10",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 11",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_11.png'
        },
        {
            "id": 0,
            "name": "Body 12",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_12.png'
        },
        {
            "id": 0,
            "name": "Body 13",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_13.png'
        },
        {
            "id": 0,
            "name": "Body 14",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_14.png'
        },
        {
            "id": 0,
            "name": "Body 15",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_15.png'
        },
        {
            "id": 0,
            "name": "Body 16",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_16.png'
        },
        {
            "id": 0,
            "name": "Body 17",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_17.png'
        },
        {
            "id": 0,
            "name": "Body 18",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_18.png'
        },
        {
            "id": 0,
            "name": "Body 19",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_19.png'
        },
        {
            "id": 0,
            "name": "Body 20",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_20.png'
        },
        {
            "id": 0,
            "name": "Body 21",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_21.png'
        },
        {
            "id": 0,
            "name": "Body 22",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_22.png'
        },
        {
            "id": 0,
            "name": "Body 23",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_23.png'
        },
        {
            "id": 0,
            "name": "Body 24",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_24.png'
        },
        {
            "id": 0,
            "name": "Body 25",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_25.png'
        },
        {
            "id": 0,
            "name": "Body 26",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_10.png'
        },
        {
            "id": 0,
            "name": "Body 27",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_27.png'
        },
        {
            "id": 0,
            "name": "Body 28",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_28.png'
        },
        {
            "id": 0,
            "name": "Body 29",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_29.png'
        },
        {
            "id": 0,
            "name": "Body 30",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_30.png'
        },
        {
            "id": 0,
            "name": "Body 31",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_31.png'
        },
        {
            "id": 0,
            "name": "Body 32",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_32.png'
        },
        {
            "id": 0,
            "name": "Body 33",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_33.png'
        },
        {
            "id": 0,
            "name": "Body 34",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_34.png'
        },
        {
            "id": 0,
            "name": "Body 35",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_35.png'
        },
        {
            "id": 0,
            "name": "Body 36",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_36.png'
        },
        {
            "id": 0,
            "name": "Body 37",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_37.png'
        },
        {
            "id": 0,
            "name": "Body 38",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_38.png'
        },
        {
            "id": 0,
            "name": "Body 39",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_39.png'
        },
        {
            "id": 0,
            "name": "Body 40",
            "location": '/Heroes/Ether/Female/White/Body/FEther_White_Body_40.png'
        },
    ]
]

export { Male, Female };