import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

import * as races from '../data/heroes/races'; //Contains data about skincolor.
import * as base_body from '../data/heroes/base_body';
import * as base_hair from '../data/heroes/base_hair';
import * as base_face from '../data/heroes/base_face';
import * as ether_body from '../data/heroes/ether_body';
import * as ether_hair from '../data/heroes/ether_hair';
import * as ether_face from '../data/heroes/ether_face';
import * as immortal_body from '../data/heroes/immortal_body';
import * as immortal_hair from '../data/heroes/immortal_hair';
import * as immortal_face from '../data/heroes/immortal_face';
import * as dragon_body from '../data/heroes/dragon_body';
import * as dragon_hair from '../data/heroes/dragon_hair';
import * as dragon_face from '../data/heroes/dragon_face';
import backgrounds from '../data/heroes/backgrounds';
import weapons from '../data/heroes/weapons';
import { useEffect } from 'react';

const GENDERS = {
    MALE: 'Male',
    FEMALE: 'Female'
}

const RACES = {
    CIRKARIANS: 0,
    KIVIATA: 1,
    MROZI: 2,
    CARDANOI: 3
}

const LEVELS = {
    BASE: "BASE",
    DRAGON_RIDERS: "DRAGON_RIDERS",
    ETHER_GUARDIANS: "ETHER_GUARDIANS",
    IMMORTAL_COUNCIL: "IMMORTAL_COUNCIL"
}

const LEVELS_BODY = {
    BASE: base_body,
    DRAGON_RIDERS: dragon_body,
    ETHER_GUARDIANS: ether_body,
    IMMORTAL_COUNCIL: immortal_body
}

const LEVELS_HAIR = {
    BASE: base_hair,
    DRAGON_RIDERS: dragon_hair,
    ETHER_GUARDIANS: ether_hair,
    IMMORTAL_COUNCIL: immortal_hair
}

const LEVELS_FACE = {
    BASE: base_face,
    DRAGON_RIDERS: dragon_face,
    ETHER_GUARDIANS: ether_face,
    IMMORTAL_COUNCIL: immortal_face
}

const Heroes = () => {
    const [currentGender, setCurrentGender] = useState(GENDERS.MALE);
    const [currentRace, setCurrentRace] = useState(RACES.MROZI);
    const [currentBody, setCurrentBody] = useState(0);
    const [currentHair, setCurrentHair] = useState(0);
    const [currentFace, setCurrentFace] = useState(0);
    const [lockedFaction, setLockedFaction] = useState(false);
    const [currentBodyUpgrade, setCurrentBodyUpgrade] = useState(LEVELS.BASE);
    const [currentHairUpgrade, setCurrentHairUpgrade] = useState(LEVELS.BASE);
    const [currentFaceUpgrade, setCurrentFaceUpgrade] = useState(LEVELS.BASE);
    const [currentBackground, setCurrentBackground] = useState(0);
    const [currentWeapon, setCurrentWeapon] = useState(0);

    useEffect(() => {
        if (currentBodyUpgrade === LEVELS.BASE && currentHairUpgrade === LEVELS.BASE && currentFaceUpgrade === LEVELS.BASE) {
            setLockedFaction(false);
        }
    }, [currentBodyUpgrade, currentHairUpgrade, currentFaceUpgrade])

    const handleBGChange = (add) => {
        if (add) {
            setCurrentBackground(currentBackground + 1);
        } else {
            if (currentBackground > 0) {
                setCurrentBackground(currentBackground - 1);
            }
        }
    }


    const handleWeaponChange = (add) => {
        if (add) {
            setCurrentWeapon(currentWeapon + 1);
        } else {
            if (currentWeapon > 0) {
                setCurrentWeapon(currentWeapon - 1);
            }
        }
    }


    const handleRaceChange = (add) => {
        if (add) {
            if (currentRace < 3) {
                setCurrentRace(currentRace+1);
            }
        } else {
            if (currentRace > 0) {
                setCurrentRace(currentRace-1);
            }
        }
    }

    const handleAssetChange = (add, type) => {
        switch(type) {
            case 'body':
                if (add) {
                    setCurrentBody(currentBody+1);
                } else {
                    if (currentBody > 0) {
                        setCurrentBody(currentBody-1);
                    }
                }
            break;
            case 'face':
                if (add) {
                    setCurrentFace(currentFace+1);
                } else {
                    if (currentFace > 0) {
                        setCurrentFace(currentFace-1);
                    }
                }
            break;
            case 'hair':
                if (add) {
                    setCurrentHair(currentHair+1);
                } else {
                    if (currentHair > 0) {
                        setCurrentHair(currentHair-1);
                    }
                }
            break;
        }
    }

    const handlePotionClick = (asset, type) => {
        setLockedFaction(type);
        if (asset === 'hair') {
            if (type === currentHairUpgrade) {
                setCurrentHairUpgrade(LEVELS.BASE);
            } else {
                setCurrentHairUpgrade(type)
            }
        }
        if (asset === 'face') {
            if (type === currentFaceUpgrade) {
                setCurrentFaceUpgrade(LEVELS.BASE);
            } else {
                setCurrentFaceUpgrade(type)
            }
        }
        if (asset === 'body') {
            if (type === currentBodyUpgrade) {
                setCurrentBodyUpgrade(LEVELS.BASE);
            } else {
                setCurrentBodyUpgrade(type)
            }
        }
    }

    return (
        <Layout>
            <HeroWindow>
                <h1>Find your perfect hero</h1>
                <div className="select-gender">
                    <button onClick={() => setCurrentGender(GENDERS.MALE)} className="selector-button">Male</button>
                    <button onClick={() => setCurrentGender(GENDERS.FEMALE)} className="selector-button">Female</button>
                </div>
                <div className="faction-locked">
                    {lockedFaction ? (
                        <div className="locked">FACTION LOCKED: {lockedFaction}</div>
                    ) : (
                        <div className="unlocked">NO FACTION</div>
                    )}
                </div>
                <div className="asset-wrap">
                    <Asset zIndex={1} src={backgrounds[currentBackground]?.location} />
                    {currentWeapon === 0 ? null : <Asset zIndex={2} src={weapons[currentWeapon]?.location} />}
                    <Asset zIndex={3} src={races[currentGender][currentRace]?.location} />
                    <Asset zIndex={4} src={LEVELS_BODY[currentBodyUpgrade][currentGender][currentRace][currentBody]?.location} />
                    <Asset zIndex={5} src={LEVELS_FACE[currentFaceUpgrade][currentGender][currentRace][currentFace]?.location} />
                    <Asset zIndex={6} src={LEVELS_HAIR[currentHairUpgrade][currentGender][currentRace][currentHair]?.location} />
                </div>
                <div className="selectors">
                    <div className="selector">
                        <button onClick={() => handleBGChange(false)} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Background</div>
                            <div className="selected">{backgrounds[currentBackground]?.name}</div>
                        </div>
                        <button onClick={() => handleBGChange(true)} className="selector-button">&rarr;</button>
                    </div>
                    <div className="selector">
                        <button onClick={() => handleRaceChange(false)} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Race</div>
                            <div className="selected">{races[currentGender][currentRace]?.name}</div>
                        </div>
                        <button onClick={() => handleRaceChange(true)} className="selector-button">&rarr;</button>
                    </div>
                    <div className="selector">
                        <button onClick={() => handleWeaponChange(false)} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Weapon</div>
                            <div className="selected">{weapons[currentWeapon]?.name}</div>
                        </div>
                        <button onClick={() => handleWeaponChange(true)} className="selector-button">&rarr;</button>
                    </div>
                    <div className="selector">
                        <button onClick={() => handleAssetChange(false, 'body')} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Body</div>
                            <div className="selected">{currentBodyUpgrade} {LEVELS_BODY[currentBodyUpgrade][currentGender][currentRace][currentBody]?.name}</div>
                            <div className="faction-upgrade-buttons">
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.IMMORTAL_COUNCIL} onClick={() => handlePotionClick('body', LEVELS.IMMORTAL_COUNCIL)} className="potion potion-immortal">immortal</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.ETHER_GUARDIANS} onClick={() => handlePotionClick('body', LEVELS.ETHER_GUARDIANS)} className="potion potion-ether">ether</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.DRAGON_RIDERS} onClick={() => handlePotionClick('body', LEVELS.DRAGON_RIDERS)} className="potion potion-dragonriders">dragonrider</button>
                            </div>
                        </div>
                        <button onClick={() => handleAssetChange(true, 'body')} className="selector-button">&rarr;</button>
                    </div>
                    <div className="selector">
                        <button onClick={() => handleAssetChange(false, 'hair')} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Hair</div>
                            <div className="selected">{currentHairUpgrade} {LEVELS_HAIR[currentHairUpgrade][currentGender][currentRace][currentHair]?.name}</div>
                            <div className="faction-upgrade-buttons">
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.IMMORTAL_COUNCIL} onClick={() => handlePotionClick('hair', LEVELS.IMMORTAL_COUNCIL)} className="potion potion-immortal">immortal</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.ETHER_GUARDIANS} onClick={() => handlePotionClick('hair', LEVELS.ETHER_GUARDIANS)} className="potion potion-ether">ether</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.DRAGON_RIDERS} onClick={() => handlePotionClick('hair', LEVELS.DRAGON_RIDERS)} className="potion potion-dragonriders">dragonrider</button>
                            </div>
                        </div>
                        <button onClick={() => handleAssetChange(true, 'hair')} className="selector-button">&rarr;</button>
                    </div>
                    <div className="selector">
                        <button onClick={() => handleAssetChange(false, 'face')} className="selector-button">&larr;</button>
                        <div className="description">
                            <div className="name">Face</div>
                            <div className="selected">{currentFaceUpgrade} {LEVELS_FACE[currentFaceUpgrade][currentGender][currentRace][currentFace]?.name}</div>
                            <div className="faction-upgrade-buttons">
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.IMMORTAL_COUNCIL} onClick={() => handlePotionClick('face', LEVELS.IMMORTAL_COUNCIL)} className="potion potion-immortal">immortal</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.ETHER_GUARDIANS} onClick={() => handlePotionClick('face', LEVELS.ETHER_GUARDIANS)} className="potion potion-ether">ether</button>
                                <button disabled={lockedFaction && lockedFaction !== LEVELS.DRAGON_RIDERS} onClick={() => handlePotionClick('face', LEVELS.DRAGON_RIDERS)} className="potion potion-dragonriders">dragonrider</button>
                            </div>
                        </div>
                        <button onClick={() => handleAssetChange(true, 'face')} className="selector-button">&rarr;</button>
                    </div>
                </div>
            </HeroWindow>
        </Layout>
    )
}

export default Heroes;

const HeroWindow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .selector-button {
        font-family: sans-serif;
        border: 2px solid #12b8a6;
        padding: 8px;
    }

    .select-gender {
        display: flex;
        align-items: center;
        margin-top: 25px;

        button {
            margin: 0 5px;
        }
    }

    .faction-locked {
        margin-top: 20px;
        font-size: 2rem;
    
        .locked {
            color: #12b8a6;
        }

        .unlocked {
            color: lightgreen;
        }
    }

    .asset-wrap {
        margin-top: 20px;
        width: 256px;
        height: 256px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .selectors {
        display: grid;
        flex-direction: column;
        justify-content: center;
        grid-template-columns: repeat(3, 220px);
        grid-gap: 20px 50px;
        .selector {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            font-family: monospace;
            position: relative;

            .faction-upgrade-buttons {
                display: flex;
                align-items: center;
                margin-top: 20px;
                position: absolute;
                top: 30px;

                .potion {
                    font-size: 10px;
                    margin: 5px;
                    font-family: sans-serif;
                    border: 2px solid #12b8a6;
                    padding: 8px;
                    text-transform: uppercase;
                    font-family: monospace;
                    font-weight: 700;

                    &:disabled {
                        border-color: grey;
                    }

                    &-immortal {
                        border-color: firebrick;
                    }

                    &-ether {
                        border-color: #784478;
                    }

                    &-dragonriders {
                        border-color: #4242b8;
                    }
                }
            }

            .description {
                width: 150px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 20px;
                .name {
                    text-decoration: underline;
                    margin-bottom: 5px;
                }
            }
        }
    }

`

const Asset = styled.img`
    width: 256px;
    height: 256px;
    image-rendering: pixelated;
    position: absolute;
    z-index: ${props => props.zIndex}
`

const Segment = ({ segmentClass, atlas, segmentId }) => {
    return (
        <SegmentWrap className={segmentClass
            + " " + atlas}
            id={segmentId}>
        </SegmentWrap>
    )
}

const SegmentWrap = styled.div`

`