const Male = [
    {
        "id": 0,
        "name": "Cirkarian",
        "location": '/Heroes/Heads/Male/Black/Male_Head_Black.png'
    },
    {
        "id": 1,
        "name": "Kiviata",
        "location": '/Heroes/Heads/Male/Green/Male_Head_Green.png'
    },
    {
        "id": 2,
        "name": "Mrozi",
        "location": '/Heroes/Heads/Male/Grey/Male_Head_Grey.png'
    },
    {
        "id": 3,
        "name": "Cardanoi",
        "location": '/Heroes/Heads/Male/White/Male_Head_White.png'
    },
]

const Female = [
    {
        "id": 0,
        "name": "Cirkarian",
        "location": '/Heroes/Heads/Female/Black/Female_Head_Black.png'
    },
    {
        "id": 1,
        "name": "Kiviata",
        "location": '/Heroes/Heads/Female/Green/Female_Head_Green.png'
    },
    {
        "id": 2,
        "name": "Mrozi",
        "location": '/Heroes/Heads/Female/Grey/Female_Head_Grey.png'
    },
    {
        "id": 3,
        "name": "Cardanoi",
        "location": '/Heroes/Heads/Female/White/Female_Head_White.png'
    },
]

export { Male, Female };