const Weapons = [
    {
        "id": 0,
        "name": "None",
        "location": '',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 1",
        "location": '/Heroes/Weapons/BasicWeapons_1.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 2",
        "location": '/Heroes/Weapons/BasicWeapons_2.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 3",
        "location": '/Heroes/Weapons/BasicWeapons_3.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 4",
        "location": '/Heroes/Weapons/BasicWeapons_4.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 5",
        "location": '/Heroes/Weapons/BasicWeapons_5.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 6",
        "location": '/Heroes/Weapons/BasicWeapons_6.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 7",
        "location": '/Heroes/Weapons/BasicWeapons_7.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 8",
        "location": '/Heroes/Weapons/BasicWeapons_8.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 9",
        "location": '/Heroes/Weapons/BasicWeapons_9.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 10",
        "location": '/Heroes/Weapons/BasicWeapons_10.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 11",
        "location": '/Heroes/Weapons/BasicWeapons_11.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 12",
        "location": '/Heroes/Weapons/BasicWeapons_12.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 13",
        "location": '/Heroes/Weapons/BasicWeapons_13.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 14",
        "location": '/Heroes/Weapons/BasicWeapons_14.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 15",
        "location": '/Heroes/Weapons/BasicWeapons_15.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 16",
        "location": '/Heroes/Weapons/BasicWeapons_16.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 17",
        "location": '/Heroes/Weapons/BasicWeapons_17.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 18",
        "location": '/Heroes/Weapons/BasicWeapons_18.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 19",
        "location": '/Heroes/Weapons/BasicWeapons_19.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 20",
        "location": '/Heroes/Weapons/BasicWeapons_20.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 21",
        "location": '/Heroes/Weapons/BasicWeapons_21.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 22",
        "location": '/Heroes/Weapons/BasicWeapons_22.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 23",
        "location": '/Heroes/Weapons/BasicWeapons_23.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 24",
        "location": '/Heroes/Weapons/BasicWeapons_24.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Basic Weapon 25",
        "location": '/Heroes/Weapons/BasicWeapons_25.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 1",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_1.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 2",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_2.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 3",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_3.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 4",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_4.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 5",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_5.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 6",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_6.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 7",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_7.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 8",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_8.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 9",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_9.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 10",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_10.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 11",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_11.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 12",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_12.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 13",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_13.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 14",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_14.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 15",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_15.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 16",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_16.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 17",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_17.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 18",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_18.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 19",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_19.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 20",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_20.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 21",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_21.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 22",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_22.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 23",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_23.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 24",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_24.png',
        "type": "Base"
    },
    {
        "id": 0,
        "name": "Legendary Weapon 25",
        "location": '/Heroes/Legendary_Weapons/LegendaryWeapons_25.png',
        "type": "Base"
    },
]

export default Weapons;