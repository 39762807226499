const Male = [
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Male/Black/Hair/MBase_Black_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Male/Green/Hair/MBase_Green_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Male/Grey/Hair/MBase_Grey_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Male/White/Hair/MBase_White_Hair_40.png'
        },
    ]
]

const Female = [
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Female/Black/Hair/FBase_Black_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Female/Green/Hair/FBase_Green_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Female/Grey/Hair/FBase_Grey_Hair_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Hair 1",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_1.png'
        },
        {
            "id": 0,
            "name": "Hair 2",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_2.png'
        },
        {
            "id": 0,
            "name": "Hair 3",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_3.png'
        },
        {
            "id": 0,
            "name": "Hair 4",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_4.png'
        },
        {
            "id": 0,
            "name": "Hair 5",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_5.png'
        },
        {
            "id": 0,
            "name": "Hair 6",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_6.png'
        },
        {
            "id": 0,
            "name": "Hair 7",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_7.png'
        },
        {
            "id": 0,
            "name": "Hair 8",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_8.png'
        },
        {
            "id": 0,
            "name": "Hair 9",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_9.png'
        },
        {
            "id": 0,
            "name": "Hair 10",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_10.png'
        },
        {
            "id": 0,
            "name": "Hair 11",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_11.png'
        },
        {
            "id": 0,
            "name": "Hair 12",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_12.png'
        },
        {
            "id": 0,
            "name": "Hair 13",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_13.png'
        },
        {
            "id": 0,
            "name": "Hair 14",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_14.png'
        },
        {
            "id": 0,
            "name": "Hair 15",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_15.png'
        },
        {
            "id": 0,
            "name": "Hair 16",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_16.png'
        },
        {
            "id": 0,
            "name": "Hair 17",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_17.png'
        },
        {
            "id": 0,
            "name": "Hair 18",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_18.png'
        },
        {
            "id": 0,
            "name": "Hair 19",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_19.png'
        },
        {
            "id": 0,
            "name": "Hair 20",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_20.png'
        },
        {
            "id": 0,
            "name": "Hair 21",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_21.png'
        },
        {
            "id": 0,
            "name": "Hair 22",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_22.png'
        },
        {
            "id": 0,
            "name": "Hair 23",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_23.png'
        },
        {
            "id": 0,
            "name": "Hair 24",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_24.png'
        },
        {
            "id": 0,
            "name": "Hair 25",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_25.png'
        },
        {
            "id": 0,
            "name": "Hair 26",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_26.png'
        },
        {
            "id": 0,
            "name": "Hair 27",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_27.png'
        },
        {
            "id": 0,
            "name": "Hair 28",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_28.png'
        },
        {
            "id": 0,
            "name": "Hair 29",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_29.png'
        },
        {
            "id": 0,
            "name": "Hair 30",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_30.png'
        },
        {
            "id": 0,
            "name": "Hair 31",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_31.png'
        },
        {
            "id": 0,
            "name": "Hair 32",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_32.png'
        },
        {
            "id": 0,
            "name": "Hair 33",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_33.png'
        },
        {
            "id": 0,
            "name": "Hair 34",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_34.png'
        },
        {
            "id": 0,
            "name": "Hair 35",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_35.png'
        },
        {
            "id": 0,
            "name": "Hair 36",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_36.png'
        },
        {
            "id": 0,
            "name": "Hair 37",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_37.png'
        },
        {
            "id": 0,
            "name": "Hair 38",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_38.png'
        },
        {
            "id": 0,
            "name": "Hair 39",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_39.png'
        },
        {
            "id": 0,
            "name": "Hair 40",
            "location": '/Heroes/Base/Female/White/Hair/FBase_White_Hair_40.png'
        },
    ]
]

export { Male, Female };