const Male = [
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Male/Black/Face/MImmortal_Black_Face_44.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Male/Green/Face/MImmortal_Green_Face_44.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Male/Grey/Face/MImmortal_Grey_Face_44.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Male/White/Face/MImmortal_White_Face_44.png'
        },
    ]
]

const Female = [
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Female/Black/Face/FImmortal_Black_Face_44.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Female/Green/Face/FImmortal_Green_Face_40.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Female/Grey/Face/FImmortal_Grey_Face_44.png'
        },
    ],
    [
        {
            "id": 0,
            "name": "Face 1",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_1.png'
        },
        {
            "id": 0,
            "name": "Face 2",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_2.png'
        },
        {
            "id": 0,
            "name": "Face 3",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_3.png'
        },
        {
            "id": 0,
            "name": "Face 4",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_4.png'
        },
        {
            "id": 0,
            "name": "Face 5",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_5.png'
        },
        {
            "id": 0,
            "name": "Face 6",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_6.png'
        },
        {
            "id": 0,
            "name": "Face 7",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_7.png'
        },
        {
            "id": 0,
            "name": "Face 8",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_8.png'
        },
        {
            "id": 0,
            "name": "Face 9",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_9.png'
        },
        {
            "id": 0,
            "name": "Face 10",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_10.png'
        },
        {
            "id": 0,
            "name": "Face 11",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_11.png'
        },
        {
            "id": 0,
            "name": "Face 12",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_12.png'
        },
        {
            "id": 0,
            "name": "Face 13",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_13.png'
        },
        {
            "id": 0,
            "name": "Face 14",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_14.png'
        },
        {
            "id": 0,
            "name": "Face 15",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_15.png'
        },
        {
            "id": 0,
            "name": "Face 16",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_16.png'
        },
        {
            "id": 0,
            "name": "Face 17",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_17.png'
        },
        {
            "id": 0,
            "name": "Face 18",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_18.png'
        },
        {
            "id": 0,
            "name": "Face 19",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_19.png'
        },
        {
            "id": 0,
            "name": "Face 20",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_20.png'
        },
        {
            "id": 0,
            "name": "Face 21",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_21.png'
        },
        {
            "id": 0,
            "name": "Face 22",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_22.png'
        },
        {
            "id": 0,
            "name": "Face 23",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_23.png'
        },
        {
            "id": 0,
            "name": "Face 24",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_24.png'
        },
        {
            "id": 0,
            "name": "Face 25",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_25.png'
        },
        {
            "id": 0,
            "name": "Face 26",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_26.png'
        },
        {
            "id": 0,
            "name": "Face 27",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_27.png'
        },
        {
            "id": 0,
            "name": "Face 28",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_28.png'
        },
        {
            "id": 0,
            "name": "Face 29",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_29.png'
        },
        {
            "id": 0,
            "name": "Face 30",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_30.png'
        },
        {
            "id": 0,
            "name": "Face 31",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_31.png'
        },
        {
            "id": 0,
            "name": "Face 32",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_32.png'
        },
        {
            "id": 0,
            "name": "Face 33",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_33.png'
        },
        {
            "id": 0,
            "name": "Face 34",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_34.png'
        },
        {
            "id": 0,
            "name": "Face 35",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_35.png'
        },
        {
            "id": 0,
            "name": "Face 36",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_36.png'
        },
        {
            "id": 0,
            "name": "Face 37",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_37.png'
        },
        {
            "id": 0,
            "name": "Face 38",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_38.png'
        },
        {
            "id": 0,
            "name": "Face 39",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_39.png'
        },
        {
            "id": 0,
            "name": "Face 40",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_40.png'
        },
        {
            "id": 0,
            "name": "Face 41",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_41.png'
        },
        {
            "id": 0,
            "name": "Face 42",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_42.png'
        },
        {
            "id": 0,
            "name": "Face 43",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_43.png'
        },
        {
            "id": 0,
            "name": "Face 44",
            "location": '/Heroes/Immortal/Female/White/Face/FImmortal_White_Face_44.png'
        },
    ]
]

export { Male, Female };